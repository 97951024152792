/* eslint-disable valid-jsdoc */
/**
 * @author Andrews.Ducloux
 * @class ImmersiveHeading
 * @classdesc JS Class handling Immersive Heading component with HTML tag 'ace-immersive-heading'
 */


export default class ImmersiveHeading extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-immersive-heading';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
      super(componentHost, componentName);
  }

  /** @inheritDoc */
  initialize() {
      super.initialize();
      this.initImmersiveHeading();
      // Responsive event handling
      window.addEventListener(CoreJS.DomEventConstants.RESIZE, () => {
        this.initImmersiveHeading();
      });
  }

  initImmersiveHeading() {
      this.isEditMode = this.componentHost.classList.contains('edit-mode');
      this.count = 1;

      this.activeElement = null;
      this.activeViewer = null;

      this.timeoutDelay = 4000;
      this.activeTimeout = null;
      this.activeTimeoutFunction = null;

      this.mediaType = this.componentHost.dataset.mediaType;
      this.totalMedia = parseInt(this.componentHost.dataset.mediaNumber);
      this.pagination = this.componentHost.querySelector('.controls-pagination');
      this.playPauseButton = this.componentHost.querySelector('button.play-pause');
      this.footerAnchor = this.componentHost.querySelector('div.footer-wrapper');

      this.narrowMediaActive = false;
      if (this.componentHost.querySelector('.narrow-content-wrapper')) {
      this.componentHost.querySelector('.narrow-content-wrapper')?.classList?.add('hidden');
      }

      if (screen.height > screen.width) {
          if (this.componentHost.querySelector('.narrow-content-wrapper')) {
            this.componentHost.querySelector('.narrow-content-wrapper')?.classList?.remove('hidden');
            this.componentHost.querySelector('.content-wrapper:not(.narrow-content-wrapper)')?.classList?.add('hidden');
            this.contentWrapperMedia = this.componentHost.querySelector('.narrow-content-wrapper:not(.hidden)');
            this.narrowMediaActive = true;
            this.mediaType = this.componentHost.dataset.narrowMediaType;
            this.totalMedia = parseInt(this.componentHost.dataset.narrowMediaNumber);
          } else {
            this.componentHost.querySelector('.content-wrapper:not(.narrow-content-wrapper)')?.classList?.remove('hidden');
            this.contentWrapperMedia = this.componentHost.querySelector('.content-wrapper:not(.hidden):not(.narrow-content-wrapper)');
          }
      } else {
        this.componentHost.querySelector('.narrow-content-wrapper')?.classList?.add('hidden');
        this.componentHost.querySelector('.content-wrapper:not(.narrow-content-wrapper)')?.classList?.remove('hidden');
        this.contentWrapperMedia = this.componentHost.querySelector('.content-wrapper:not(.hidden):not(.narrow-content-wrapper)');
      }
      if (this.totalMedia > 1) {
        this.pagination.innerText = `${this.count}/${this.totalMedia}`;
      } else {
        if (this.pagination) {
          this.pagination.style.display = 'none';
        }
      }

      if (!this.isEditMode) {
          if (this.mediaType === 'images') {
              this.activateElement(this.contentWrapperMedia);
              this.playPauseButton.classList.add('pause-display');
              this.playPauseButton.classList.remove('play-display');
              this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.playAriaLabel;
              this.playPauseBehavior();
          } else {
              document.addEventListener(CoreJS.CustomDomEventConstants.VIDEO_COMPONENT_LOADED, () => {
                this.activateElement(this.contentWrapperMedia);
                this.playPauseBehavior();
              });
          }

          this.footerAnchor?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
              window.scroll({top: `${this.componentHost.clientHeight}`, behavior: 'smooth'});
          });
      }

      // Set default autoplay enabled
      if (this.mediaType === 'videos') {
        this.componentHost.querySelectorAll('.content-wrapper')?.forEach((item) => {
          item.querySelector('.video-viewer-component')?.setAttribute('data-auto-play', '1');
        });
      }
  }
  /**
   * @param htmlElement
   */
  activateElement(htmlElement) {
      function bindVideoEndEvent(event, that) {
          that.activeViewer.videoplayer.seek(0);
          that.count++;
          if (that.count > that.totalMedia) {
              that.count = 1;
          }
          that.pagination.innerText = `${that.count}/${that.totalMedia}`;
          let nextElement = that.componentHost.querySelector(`.wrapper-${that.count}:not(.narrow-content-wrapper)`);
          if (that.narrowMediaActive) {
            nextElement = that.componentHost.querySelector(`.narrow-content-wrapper.wrapper-${that.count}`);
          }
          const nextAssociatedId = nextElement.querySelector('.video-viewer-component').id;
          const nextViewer = window.videoViewers.find((viewer) => viewer.containerId === nextAssociatedId);
          nextViewer.init();
          that.activateElement(nextElement);
          document.querySelector(`#${nextAssociatedId} video`).muted = 'muted';
          nextViewer.videoplayer.play();
      }

      if (htmlElement) {
          let count = 0;
          htmlElement.classList.remove('hidden');
          if (this.activeElement) {
              this.deactivateElement();
          }

          this.activeElement = htmlElement;

          if (this.mediaType === 'videos') {
              const associatedVideoId = this.activeElement.querySelector('.video-viewer-component').id;
              if (window.videoViewers) {
                  this.activeViewer = window.videoViewers.find((viewer) => viewer.containerId === associatedVideoId);
                  this.activeViewer?.init();
                  if (this.activeViewer) {
                      const videoElement = document.getElementById(associatedVideoId);
                      if (screen.height <= screen.width && this.componentHost.querySelector('.narrow-content-wrapper') && count === 0) {
                        const nextViewerFirst = window.videoViewers.find((viewer) => viewer.containerId === associatedVideoId);
                        nextViewerFirst.init();
                      }
                      count ++;
                      videoElement.addEventListener('video-complete', () => {
                          const nativeVideoElement = document.getElementById(associatedVideoId).querySelector('video');
                          this.playPauseButton.disabled = null;
                          this.activeViewer.videoplayer?.addEventListener('notfVideoEnd', (event) => {
                              if (this.totalMedia > 1) {
                                  bindVideoEndEvent(event, this);
                              }
                          });
                          nativeVideoElement.addEventListener('play', () => {
                              this.playPauseButton.classList.add('pause-display');
                              this.playPauseButton.classList.remove('play-display');
                              this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.playAriaLabel;
                          });
                          ['suspend', 'pause', 'error', 'abort'].forEach((event) => {
                              nativeVideoElement.addEventListener(event, () => {
                                  this.playPauseButton.classList.remove('pause-display');
                                  this.playPauseButton.classList.add('play-display');
                                  this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.pauseAriaLabel;
                              });
                          });
                      });
                  }
              }
          } else {
              if (this.totalMedia > 1) {
                  this.playPauseButton.disabled = null;
                  this.activeTimeoutFunction = () => {
                      this.count++;
                      if (this.count > this.totalMedia) {
                          this.count = 1;
                      }
                      this.pagination.innerText = `${this.count}/${this.totalMedia}`;
                      if (this.narrowMediaActive) {
                        this.activateElement(this.componentHost.querySelector(`.narrow-content-wrapper.wrapper-${this.count}`));
                      } else {
                        this.activateElement(this.componentHost.querySelector(`.wrapper-${this.count}:not(.narrow-content-wrapper)`));
                      }
                  };
                  this.activeTimeout = setTimeout(this.activeTimeoutFunction, this.timeoutDelay);
              }
          }
      }
  }
  /**
   * @param htmlElement
   */
  deactivateElement() {
      this.activeElement.classList.add('hidden');
  }

  playPauseBehavior() {
      if (this.mediaType === 'videos' || this.mediaType === 'images' && this.totalMedia > 1) {
        const isSlowDevice = document.body.classList.contains('slow-connection-device');
        if (isSlowDevice) {
          this.playPauseButton.classList.remove('pause-display');
          this.playPauseButton.classList.add('play-display');
          this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.pauseAriaLabel;
        }

        this.playPauseButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          if (this.playPauseButton.classList.contains('pause-display')) {
            if (this.mediaType === 'videos' && this.activeViewer.videoplayer) {
              this.activeViewer.videoplayer.pause();
            } else {
              clearTimeout(this.activeTimeout);
            }
            this.playPauseButton.classList.remove('pause-display');
            this.playPauseButton.classList.add('play-display');
            this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.pauseAriaLabel;
          } else {
            if (this.mediaType === 'videos' && this.activeViewer.videoplayer) {
              this.activeViewer.videoplayer.play();
            } else {
              this.activeTimeout = setTimeout(this.activeTimeoutFunction, this.timeoutDelay);
            }
            this.playPauseButton.classList.add('pause-display');
            this.playPauseButton.classList.remove('play-display');
            this.playPauseButton.querySelector('span').innerText = this.playPauseButton.dataset.playAriaLabel;
          }
        });
      }
  }
}
// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(ImmersiveHeading.CLASS_NAMESPACE, ImmersiveHeading);
