/* eslint-disable no-unused-expressions */
/**
 * Class representing a Novotel Heading Hero Component instance
 * @author Soujanya
 * @class NovotelHeadingHero
 * @classdesc Novotel heading hero component to inherit the heading hero core component
 * @extends HeadingHero
 * @version 1.0
 */
import { HeadingHero } from '@accor/ace-ui-core';

export default class NovotelHeadingHero extends HeadingHero {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    this.headingUserLoginStatus();
  }
  headingUserLoginStatus() {
    const buttonmember = this.componentHost?.querySelector('.ace-heading-component .ace-headingpagehero .ace-headingpagehero__content .cmp-button ');
    if (CoreJS.CommonUtils.userLoginStatus() === true) {
      buttonmember?.classList?.add('btnmemberhidden');
    }
  }
}


// Register the heading hero container component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(HeadingHero.CLASS_NAMESPACE, NovotelHeadingHero, true);
