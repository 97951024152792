/* eslint-disable no-unused-expressions */
/**
 * Class representing a Novotel Quick Access Component instance
 * @author Rishabh
 * @class QuickAccessV2
 * @classdesc Novotel quick access component V2 to inherit the quick access core component
 * @extends NovotelQuickAccessV2
 * @version 1.0
 */
import { QuickAccessV2, TagManager } from '@accor/ace-ui-core';

export default class NovotelQuickAccessV2 extends QuickAccessV2 {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    this.quickAccessNavItems = this.componentHost.querySelectorAll('.cmp-ace-anchor-navigation__tabs__anchors ul li');
    this.tagManagerHandler();
  }

  tagManagerHandler() {
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      this.quickAccessNavItems.forEach((element, index) => {
        element?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: 'GA4event',
            eventName: 'menu_inpage',
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              menu_position: index + 1,
              bloc_name: element?.innerText?.trim()?.toLowerCase()
            }
          };
          TagManager.trackEvent(dataEvent);
        });
      });
    }
  }
}


// Register the QuickAccess component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(QuickAccessV2.CLASS_NAMESPACE, NovotelQuickAccessV2, true);
